import http from "../constants/token-interceptors";
import { logoutuser } from "../constants/microservice-helpers";
import axios from "axios";
export const USER_LOGOUT = "USER_LOGOUT";

export function onLogoutUser() {
  const type = sessionStorage.getItem("type");
  var customObject = JSON.parse(sessionStorage.getItem("pxCustomData"));
  if (window && window.pxSendCustomData && customObject) {
    customObject["namespace"] = process.env.REACT_APP_NAMESPACE;
    customObject["user.action"] = "logout";
    const currentTime = new Date().getTime();
    customObject["user.sessionTime"] =
      currentTime - customObject["user.loginTime"];
    delete customObject["user.loginTime"];
    customObject["user.loginStatus"] = "not logged in";
    customObject["user.logoutTime"] = new Date().getTime();
    sessionStorage.setItem("pxCustomData", JSON.stringify(customObject));
    window.pxSendCustomData(customObject);
  }
  return (dispatch) => {
      if (
        process.env.REACT_APP_OID_FLAG &&
        process.env.REACT_APP_OID_FLAG === "true"
      ) {
        window.location = logoutUrlOID();
        sessionStorage.clear();
        return false;
      } else {
        if (type && type === "msid") {
          window.location = logoutUrlOID();
          sessionStorage.clear();
        } else {
          logoutUrlOHID();
        }
      }
    return null;
    sessionStorage.clear();
    window.location = process.env.REACT_APP_CLIENTPORTAL_URL;
    return null;
  };
}

function logoutUrlOID() {
  const type = sessionStorage.getItem("type");
  const dynamicUrl = checkRedirectLogoutURL(window.location.href);
  if (type && type === "msid") {
    return (
      process.env.REACT_APP_MSID_LOGOUT_URL +
      `/idp/startSLO.ping?Target=${window.location.origin + dynamicUrl}`
    );
  } else {
    return (
      process.env.REACT_APP_OPTUMID_LOGOUT_URL +
      `/tb/oidc/logout?post_logout_redirect_uri=${
        window.location.origin + dynamicUrl
      }`
    );
  }
}

function logoutUrlOHID() {
  const type = sessionStorage.getItem("type");
  const dynamicUrl = checkRedirectLogoutURL(window.location.href);
  if (type && type === "msid") {
    return (
      process.env.REACT_APP_MSID_LOGOUT_URL +
      `/idp/startSLO.ping?Target=${window.location.origin + dynamicUrl}`
    );
  } else {
    functionCallIdToken(dynamicUrl);
  }
  return null;
}

function checkRedirectLogoutURL(location) {
  if (location) {
    if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "HOME"
    ) {
      return "/";
    } else if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "MEMBERIDGENERATOR"
    ) {
      return "/";
    } else if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "MS"
    ) {
      return "/";
    } else if (
      location.split("/")[3] &&
      location.split("/")[3].toLocaleUpperCase() === "OID"
    ) {
      return "/";
    } else {
      return "/" + window.location.href.split("/")[3];
    }
  }
  return null;
}

async function functionCallIdToken(dynamicUrl) {
  const url = logoutuser();
  const sessionId = sessionStorage.getItem("session_id");
  if(!sessionId)
  {
    sessionStorage.clear();
    localStorage.clear();
    window.location = process.env.REACT_APP_CLIENTPORTAL_URL + "/login";
  }
  else
  {
  const requestHeader = {
    authorization: { sessionId },
  };
  const { status, data: token } = await axios.get(url, {
    headers: requestHeader,
  });
  try {
    if (status === 200 && token) {
      const newLocal = process.env.REACT_APP_OHID_LOGOUT_URL +
        `?id_token_hint=${token}&post_logout_redirect_uri=${process.env.REACT_APP_CLIENTPORTAL_URL + dynamicUrl}/&state=clientportal${getRandomInt(1, 99)}`;
      window.location = newLocal;
      sessionStorage.clear();
      localStorage.clear();
    } else {
      window.location = process.env.REACT_APP_CLIENTPORTAL_URL + "/login";
      sessionStorage.clear();
      localStorage.clear();
    }
  } catch (error) {
    window.location = process.env.REACT_APP_CLIENTPORTAL_URL + "/login";
    sessionStorage.clear();
    localStorage.clear();
  }
}
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
